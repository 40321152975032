import React from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql} from 'gatsby'
import iconInstagram from '../assets/svg/social_instagram.svg'
import iconYoutube from '../assets/svg/social_youtube.svg'
import iconLinkedin from 'assets/svg/social_linkedin.svg'
import iconEmail from '../assets/svg/icon_email.svg'

const Footer = ({menus}) => {

  const data = useStaticQuery(graphql`
        query {
            contentfulContactDetails {
                id
                addressLine1
                addressLine2
                contactNumber
                email
                facebookUrl
                twitterUrl
                instagramUrl
                youtubeUrl
            }
        }
    `);

  const footerMenu = (menus !== null && menus !== undefined) ? menus.find(menu => menu.type === 'secondary') : null;

  return (
    <footer className="footer bg-tertiary text-white">
      <div className="container section mx-auto py-10">
        <div className="footer__content">
          <h3 className="text-lg font-bold mb-4">Kontakt</h3>
          <ul className="text-sm">
            <li className="mb-3">
              <div className="item">
                <img className="w-5 h-5 mr-4" src={iconEmail} alt="MyFlexHome e-Mail"/>
                <div className="item__content">
                  <p className="item__text">
                    <a className="hover:text-primary" href={data.contentfulContactDetails.email}>{data.contentfulContactDetails.email}</a>
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-3">
              <div className="item">
                <a className="hover:text-primary" href="/datenschutz/">Datenschutz</a> &nbsp; | &nbsp;
                <a className="hover:text-primary" href="/impressum/">Impressum</a>
              </div>
            </li>
          </ul>
        </div>
        {
          (footerMenu !== null && footerMenu !== undefined) &&
          <div className="footer__content">
            <h3 className="text-lg font-bold mb-4">Mehr über MyFlexHome</h3>
            <ul className="text-sm">
              {
                //footerMenu.menuItems.map(menu => (
                //  <li className="mb-2" key={menu.id}><a className="hover:text-primary" href={menu.url}>{menu.title}</a>
                //  </li>
                //))
              }
              <li className="mb-2">
                <a className="hover:text-primary" href="#so-funktionierts">So funktioniert's</a>
              </li>
              <li className="mb-2">
                <a className="hover:text-primary" href="#vorteile">Deine Vorteile</a>
              </li>
              <li className="mb-2">
                <a className="hover:text-primary" href="#anmelden">Newsletter Anmeldung</a>
              </li>
              <li className="mb-2">
                <a className="hover:text-primary" href="/info/hotels">Für Hotels</a>
              </li>
              <li className="mb-2">
                <a className="hover:text-primary" href="/info/unternehmen">Für Unternehmen</a>
              </li>
              <li className="mb-2">
                <a className="hover:text-primary" href="/info/zweitwohnsitzsteuer">Zweitwohnsitzsteuer vermeiden</a>
              </li>
              <li className="mb-2">
                <a className="hover:text-primary" href="/info/presse">Presse</a>
              </li>
            </ul>
          </div>
        }
        <div className="footer__content">
          <h3 className="text-lg font-bold mb-4">Folge uns</h3>
          <ul className="flex text-sm">
            <li className="mb-2 mr-4">
              <a href={data.contentfulContactDetails.instagramUrl}>
                <img className="w-6 h-6" src={iconInstagram} alt="MyFlexHome Instagram"/>
              </a>
            </li>
            <li className="mb-2 mr-4">
              <a href={data.contentfulContactDetails.youtubeUrl}>
                <img className="w-6 h-6" src={iconYoutube} alt="MyFlexHome YouTube"/>
              </a>
            </li>
            <li className="mb-2 mr-4">
              <a href={data.contentfulContactDetails.facebookUrl}>
                <img className="w-6 h-6" src={iconLinkedin} alt="MyFlexHome LinkedIn"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  menus: null
};

Footer.propTypes = {
  menus: PropTypes.any
};


export default Footer;
